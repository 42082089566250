/**
 * Function to get the first part of a url
 * @example
 * getUrlHost("https://app.progressionapp.com") > "app"
 * getUrlHost("https://progressionapp.com") > "progressionapp"
 */

export const currentUrl = () => {
  return new URL(window.location.href)
}

export const getUrlHost = (baseUrl: string) => {
  return baseUrl.replace(/https?:\/\//g, '').split('.')[0]
}

export const removeUrlParams = (...params: string[]) => {
  const url = currentUrl()
  let found = false

  for (const param of params) {
    found = found || url.searchParams.has(param)
    url.searchParams.delete(param)
  }

  if (!found) return
  window.history.replaceState(window.history.state, '', url.toString())
}

export const urlWithoutProtocol = (url: string) => {
  return url.replace(/^https?:\/\//, '')
}
